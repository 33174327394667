.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  z-index: 9999;
}

.preloader-icon {
  border: 1px solid var(--clr-green);
  padding: 1em;
}

svg.preloader-icon > path {
  fill-opacity: 0;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  -webkit-animation: animate-svg 5s ease-out forwards;
          animation: animate-svg 5s ease-out forwards;
}

@-webkit-keyframes animate-svg {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-svg {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}/*# sourceMappingURL=preloader.css.map */