.primary-header {
  position: fixed;
  flex-direction: column;
  width: 130px;
  height: 100vh;
  flex: 1;
  top: 0;
  left: 0;
  bottom: 0;
  justify-content: space-between;
  background: var(--clr-bg-light);
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: var(--clr-bg-dark);
  padding: 2rem 0;
}

.logo-container p {
  margin-top: 1rem;
  font-size: 12px;
  color: var(--clr-text-secondary);
}

.sub-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sub-logo-container span {
  font-size: 20px;
  color: var(--clr-text-primary);
}

.logo {
  width: 4em;
  aspect-ratio: 1;
}

.logo-link {
  height: 100%;
}

.mobile-nav-toggle {
  display: none;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.primary-navigation {
  flex-direction: column;
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 500;
  justify-content: center;
  align-items: center;
  --gap: 0rem;
}

.primary-navigation li {
  border-top: 1px solid hsl(0, 0%, 16%);
  width: 100%;
}

.primary-navigation a {
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  display: block;
  color: var(--clr-text-secondary);
  line-height: 3rem;
  border-bottom: 1px solid hsl(0, 0%, 16%);
  transition: color 0.3s, background-color 0.3s;
}

.primary-navigation a:hover {
  color: var(--clr-green);
}

.social {
  color: var(--clr-text-secondary);
  margin: 0;
  padding-block: 0;
  padding-inline: 2px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  height: 20rem;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.social li {
  text-align: center;
}

.social li a {
  padding: 2rem 7px;
}

@media (max-width: 81rem) {
  .primary-header {
    width: 100%;
    z-index: 1000;
    clip-path: circle(0% at 95.3% 8.5%);
    transition: clip-path 500ms ease-out;
  }

  .primary-navigation {
    height: auto;
  }

  @supports (backdrop-filter: blur(1rem)) {
    .primary-navigation {
      background: hsl(0 0% 100% / 0.1);
      backdrop-filter: blur(1rem);
    }
  }

  .mobile-nav-toggle {
    display: block;
    position: fixed;
    width: fit-content;
    height: fit-content;
    background-color: var(--clr-bg-dark);
    top: 1.8rem;
    right: 1rem;
    overflow: hidden;
    z-index: 9999;
    border: 1px solid var(--clr-bg-dark);
    cursor: pointer;
  }

  .hamburger {
    color: var(--clr-text-secondary);
    display: block;
    margin: auto;
    transition: translate 600ms, rotate 600ms;
  }

  .line {
    transition: 600ms;
    stroke-dasharray: 60 31 60 300;
  }

  .mobile-nav-toggle[aria-expanded='true'] .hamburger {
    translate: 4px -2px;
    rotate: 0.125turn;
  }

  .mobile-nav-toggle[aria-expanded='true'] .line {
    stroke-dasharray: 60 105 60 300;
    stroke-dashoffset: -89;
  }

  .primary-header[data-nav-opened='true'] {
    clip-path: circle(140% at 95.3% 8.5%);
  }
}
