@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Playfair+Display:ital@0;1&display=swap');

:root {
  --clr-light: hsl(0, 0%, 100%);
  --clr-green: hsl(172, 100%, 50%);

  --clr-bg: hsl(0, 0%, 9%);
  --clr-bg-light: hsl(0, 0%, 11%);
  --clr-bg-dark: hsl(0, 0%, 0%);

  --clr-text-primary: hsl(0, 0%, 100%);
  --clr-text-secondary: hsl(0, 0%, 77%);
  --clr-text-tertiary: hsl(0, 0%, 37%);
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--clr-green);
  border-radius: 100vw;
}

*::-webkit-scrollbar-thumb:hover {
  background: hsl(172, 100%, 30%);
}

@supports (scrollbar-color: var(--clr-green) transparent) {
  * {
    scrollbar-color: var(--clr-green) transparent;
    scrollbar-width: 3px;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

body {
  width: 100%;
  min-height: 100vh;
  background: var(--clr-bg);
  font-family: 'Open Sans', sans-serif;
  color: var(--clr-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-transform: capitalize;
  color: var(--clr-text-secondary);
  transition: color 0.4s ease-out;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus {
  color: var(--clr-green);
}

li {
  padding: 0;
  margin: 0;
}

.html-open-element {
  display: block;
  margin-block: 0.8em;
  max-width: 4vw;
  max-height: 3vw;
  padding: 0;
}

.html-closing-element {
  display: block;
  margin-block: 0.8em;
  max-width: 5vw;
  max-height: 3vw;
  padding: 0;
}

.sr-only {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  top: auto;
  overflow: hidden;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

@font-face {
  font-family: 'Herbarium';
  src: url('fonts/HerbariumRegular.woff2') format('woff2'),
    url('fonts/HerbariumRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
