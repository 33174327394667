@use 'sass:math';
@use 'sass:string';

.projects {
  position: relative;
  flex-direction: column;
  width: calc(100vw - 135px);
  margin-left: 130px;
  padding-block: 5vh;
  padding-inline: clamp(3rem, 4vw, 5rem);
  gap: 2rem;
}

.projects .gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
  height: 100%;
  width: 100%;
}

.projects .gallery > * {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  max-width: 40vh;
}

.projects .heading {
  font-family: 'Playfair Display', sans-serif;
  font-weight: 400;
  font-size: clamp(2rem, 5vw, 7rem);
  line-height: 0.8em;
}

.projects .heading > span {
  transition: color 400ms ease-out;
}

.projects .heading > span:hover {
  color: var(--clr-green);
}

.projects .subheading {
  color: var(--clr-text-secondary);
  font-size: clamp(0.6rem, 1.3vw, 3rem);
  width: clamp(50%, 50vw, 70%);
}

.projects .subheading > a {
  color: var(--clr-green);
}

.projects .subheading > a:hover,
.projects .subheading > a:focus {
  opacity: 0.6;
}

.project-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: inline-block;
  transition: all ease-in-out 0.5s;
}

.project-description {
  width: 100%;
  height: 0%;
  background-color: hsla(300, 1%, 17%, 0.8);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: all ease-in-out 0.5s;
}

.project:hover .project-description,
.project:focus .project-description {
  height: 100%;
}

.project-title {
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 1em;
}

.project-short-desc {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.project-demo-button {
  margin-top: 30px;
  margin-bottom: 1em;
  color: var(--clr-bg-dark);
  font-size: 18px;
  background-color: hsl(0, 0%, 94%);
  width: 50px;
  border-radius: 50%;
  padding: 0.5em;
}

.project-demo-button:hover,
.project-demo-button:focus {
  color: var(--clr-bg-dark);
  background-color: var(--clr-green);
}

@media (max-width: 81rem) {
  .projects {
    width: 100vw;
    margin: 0;
    padding-inline: clamp(2em, 10vw, 5em);
  }
}

@media (min-width: 800px) {
  .projects {
    padding-inline: 2em;
    padding-block: 10vh;
    min-height: 100vh;
  }
}
