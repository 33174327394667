@use 'sass:math';
@use 'sass:string';

.skills {
  position: relative;
  flex-direction: column;
  width: calc(100vw - 135px);
  margin-left: 130px;
  padding-block: 5vh;
  padding-inline: clamp(3rem, 4vw, 5rem);
  gap: 2rem;
}

.skills .text-container {
  width: clamp(18em, 50vw, 70%);
}

.skills .subheading {
  color: var(--clr-text-secondary);
  font-size: clamp(0.6rem, 1.3vw, 3rem);
  width: 100%;
  transition: color 0.5s ease-out;
}

.skills .subheading > a {
  color: var(--clr-green);
}

.skills .subheading > a:hover,
.skills .subheading > a:focus {
  opacity: 0.6;
}

.skills .heading {
  font-family: 'Playfair Display', sans-serif;
  font-weight: 400;
  font-size: clamp(2rem, 5vw, 7rem);
  line-height: 0.8em;
}

.skills .heading > span {
  transition: color 400ms ease-out;
}

.skills .heading > span:hover {
  color: var(--clr-green);
}

.skills .green-text {
  color: var(--clr-green);
}

.skills .stack {
  list-style: none;
  counter-set: stack-counter 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  font-size: clamp(0.5rem, 1vw, 3rem);
}

.skills .stack li {
  color: var(--clr-text-secondary);
  counter-increment: stack-counter 1;
  margin-block: 0.2em;
}

.skills .stack li::before {
  content: counter(stack-counter) ')';
  margin-right: 1em;
  color: var(--clr-green);
  font-size: clamp(0.5rem, 1vw, 23em);
  border-radius: 50%;
}

.skills .skill-icons {
  display: none;
}

.skills .skill-icons > * {
  color: var(--clr-text-secondary);
  font-size: clamp(5rem, 7vw, 6rem);
}

@media (max-width: 81rem) {
  .skills {
    width: 100%;
    margin: 0;
    padding-inline: clamp(2em, 10vw, 5em);
  }
}

@media (min-width: 800px) {
  .skills {
    padding-inline: 2em;
    flex-direction: row;
    padding-block: 10vh;
  }
  .skill-icons {
    display: grid !important;
    height: 100%;
    padding: 9em;
    gap: 1.5em;
    grid-template-columns: repeat(3, minmax(20px, 1fr));
  }
}
