.contact {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  width: calc(100vw - 135px);
  min-height: 50vh;
  padding-block: 10vh;
  margin-left: 130px;
  padding-inline: clamp(3rem, 4vw, 5rem);
  gap: 2rem;
}

.contact .heading {
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  font-size: clamp(2rem, 5vw, 7rem);
  line-height: 0.8em;
}

.contact .heading > span {
  transition: color 400ms ease-out;
}

.contact .heading > span:hover {
  color: var(--clr-green);
}

.contact .subheading {
  color: var(--clr-text-secondary);
  font-size: clamp(0.6rem, 1.3vw, 3rem);
  width: 100%;
  padding-block: 0.5em;
  transition: color 0.5s ease-out;
}

.contact .subheading > a {
  color: var(--clr-green);
}

.contact .subheading > a:hover,
.contact .subheading > a:focus {
  opacity: 0.6;
}

.contact .contact-btn {
  color: var(--clr-text-secondary);
  text-transform: uppercase;
  background: var(--clr-bg-light);
  border: 0.1em solid var(--clr-green);
  padding: 0.5em 1.25em;
  transition: all 300ms ease-out;
  border-radius: 0.2em;
  font-size: clamp(0.6rem, 1vw, 1.5rem);
}

.contact-btn:hover {
  color: var(--clr-bg-dark);
  background: var(--clr-green);
}

.contact-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.contact-form > *:not(button) {
  width: 100%;
  color: var(--clr-text-primary);
  background-color: var(--clr-bg-light);
  padding: 1em;
  border-radius: 0.2em;
  font-size: clamp(0.6rem, 1vw, 2rem);
}

.contact-form > *:nth-child(1) {
  margin-top: 1em;
}

.contact-form > button {
  color: var(--clr-text-secondary);
  text-transform: uppercase;
  background: var(--clr-bg-light);
  border: 0.1em solid var(--clr-green);
  padding: 0.5em 1.25em;
  transition: all 300ms ease-out;
  margin-top: 2em;
  border-radius: 0.2em;
  font-size: clamp(0.6rem, 1vw, 1.5rem);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
}

.contact-form > button:hover {
  color: var(--clr-bg-dark);
  background: var(--clr-green);
}

.green-text {
  color: var(--clr-green);
}

@media (max-width: 81rem) {
  .contact {
    width: 100%;
    margin: 0;
    padding-inline: clamp(2em, 10vw, 5em);
  }
}
@media (min-width: 800px) {
  .contact {
    padding-inline: 2em;
    grid-auto-flow: column;
    grid-template-columns: 50% 50%;
  }
  .contact-form-container {
    padding-top: 3em;
    padding-inline: 2em;
  }
  .contact .text-container {
    padding-top: 1em;
  }
}/*# sourceMappingURL=contact.css.map */